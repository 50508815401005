import React, { useLayoutEffect } from "react";
import BlogImg1 from "../../../assets/massage-2-390x344.jpg";
import UserImg from "../../../assets/user.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faComment } from "@fortawesome/free-regular-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";

const GlutathioneWhiteningTreatment: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className="section section-lg bg-primary container-top">
        <div className="container ">
          <div className="text-center ">
            <h1>Glutathione Whitening Treatment</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-9">
              <div className="mb-5">
                <h3 className="mb-4">What is Glutathione?</h3>

                <p>
                  Glutathione is a substance produced naturally by the liver .
                  It is also found in fruits, vegetables, and meats.
                </p>

                <p>
                  Glutathione is made up of three amino acids: cysteine,glycine,
                  and glutamate.
                </p>

                <p>
                  Glutathione acts as an important antioxidant in your body.
                  That means it helps combat free radicals generated in your
                  body. Free radicals have the tendency to damage your body’s
                  healthy cell.
                </p>

                <p>
                  As body ages, or suffering from other diseases such as stroke,
                  HIV, cancer, etc. glutathione level starts decreasing.
                </p>

                <h5>Can you get Glutathione naturally from Foods?</h5>
                <p>
                  Your body doesn’t seem to absorb glutathione well from foods.
                  However, certain foods contain glutathione
                </p>

                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    These include
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Unprocessed meat
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Garlic
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Broccoli
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Asparagus
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Avocados
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Spinach
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    In what conditions it is being supplemented?
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    It is being supplemented as oral or injectable glutathione
                    for various conditions nowadays.
                  </p>
                </p>

                <h5>Some of the skin conditions are</h5>

                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Preventing aging
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    For skin whitening or complexion enhancement.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Pigmentation of face
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Melasma /cholasma patches
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Glutathione is also used for maintaining the body’s defense
                    system (immune system) and fighting metal and drug
                    poisoning.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    How is it Beneficial in Skin Whitening?
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Various studies point out that
                  </p>
                </p>

                <p>
                  Glutathione inhibits melanin formation (pigment material of
                  skin) by quenching of free radicals and peroxides that
                  contribute to tyrosinase activation and melanin formation. Its
                  antioxidant property also protects the skin from UV radiation
                  and other environmental as well as internal stressors that
                  generate free radicals that cause skin damage and
                  hyper-pigmentation.
                </p>

                <h5>How can it be taken?</h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Since it cannot be absorbed easily from food, it has to be
                    supplemented external sources.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Oral: daily 500 mg twice daily dose or single 1000mg dose
                    atleast.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    It takes minimum 3-6 months for the results to be seen.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    {/* Injectable: Glutathione injections can be given into the
                    patches of pigmentation or in combination with other
                    procedures like Mesotherapy,Dermaroller or Oxygen Treatment. */}
                    Injectable: Glutathione injections can be given into the patches of 
                    pigmentation or in combination with other procedures like Mesotherapy,
                    Dermaroller or Oxygen Treatment.intravenous glutathione therapy with 
                    vitamin c and Nacetyl cysteine works on lightening ,brightning and glow.

                  </p>
                </p>

                <h5>Why Skin & You Clinic for glutathione treatment?</h5>
                <p>
                  We have been working with this revolutionary product in
                  combination with different treatments. We understand the
                  benefits of the product and we have novel methods for product
                  penetration into the skin with effective result.
                </p>

                <p>
                  The treatments are safe without any downtime or side effects.
                </p>
              </div>
            </div>

            <div className="col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GlutathioneWhiteningTreatment;
