import React, { useLayoutEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";

const InchLoss: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="section section-lg bg-primary container-top">
        <div className="container ">
          <div className="text-center ">
            <h1>Inch Loss</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-9">
              <div className="mb-5">
            
              <p>I- Lipo Inch Loss / Body Shaping</p>
              <h5>WHAT YOU WILL FEEL DURING THE TREATMENT</h5>

<p>A comfortable, relaxing treatment with a typical sensation of warmth.</p>
<h5>A PAINLESS TREATMENT</h5>

<p>No pain is experienced during the treatment.</p>
<h5>EFFECTIVENESS OF THE TREATMENT</h5>
<p>Inch loss varies from individual to individual. Generally a reduction of 4-6 inches is seen in the patient. At least, one to two dress-size reduction has been documented.</p>



<h5>MAINTENANCE PLAN</h5>
<p>A diet advised by us should be followed. An exercise routine also helps. If there is no weight gain, maintenance treatments are seldom required.
</p>
<h5>DIET ADVICE</h5>
<p>Have 5 proper meals a day; it should be a balanced diet, with 5 portions of fruits and 2 portions of vegetables. Avoid missing meals.
</p>
<h5>ABOUT CELLULITE</h5>
<p>Cellulite is basically fat that changes compartments due to laxity in skin structure. This contributes to it getting stuck between the fibrotic bands in the skin, resulting in changes of irregular skin with dimpling, so it looks almost like an orange peel.</p>



<h5>COMMON AREAS FOR CELLULITE</h5>
<p>Buttocks, Abdomen, Thighs</p>



<h5>COMMON TRIGGERS FOR CELLULITE</h5>
<p>Lack of Exercise
Female Gender
Overweight/Obesity
Elderly
Poor Lymphatic Drainage
Excess Hormones</p>


<h5> ELECTRO CELLULITE MASSAGER</h5>
<p>The stimulatory action of Electro Cellulite Massager tones the muscles and reduces inches by improving circulation and lymphatic drainage, thereby breaking down fatty deposits. This is followed by the I-LIPO treatment.</p>

<h5>DVICE FOR LASER INCH LOSS AND BODY SHAPING</h5> 
<h5> PRE TREATMENT PROTOCOL</h5>
<p>Please avoid heavy meals for 2-4 hours before and after each treatment as the body gets confused about which fat to metabolise. If you are hungry, munch on some fruit.
Do not fast before the treatment as the body may go into starvation mode.
Avoid coffee, tea and carbonated drinks before the treatment as these may cause bloating.
Good hydration is must for a healthy lymphatic system, so make sure you drink enough water.
Avoid going in for the treatment around the time of your menstruation cycle.
To actually measure the results, wear the same clothes on the last day and the first day of the treatment.</p>
<h5>POST TREATMENT PROTOCOL</h5>
<p>Post treatment, exercise is a must as it speeds up the metabolic rate and helps use up the mobilized fat, thus preventing it from being restored.
Adequate hydration is a must, both pre and post treatment.
Avoid eating immediately after the session. You may eat 2-3 hours after the treatment
Follow your diet routine as advised.
Your measurements are taken pre and post the treatment in order to monitor the progress.</p>
<h5>WHY I-LIPO</h5> 
<p>Simply because it has many advantages over other systems. Here are just a few</p>



<h5> CLINICALLY PROVEN</h5>
<p>Independent clinical studies have shown that in some cases I-Lipo has similar results like liposuction. Ultrasound imagery shows up to 30% reduction in the fat layer depth after just one treatment. Additional treatments improve results further. Results can be seen immediately after each treatment as the fat cell contents are released. Light exercise post treatment can further accelerate the removal of the released fat.
</p>
<h5> AFFORDABLE TREATMENT</h5>
<p>Compared to surgical liposuction, I-Lipo is much more affordable with similar results.</p>

<h5> SAFE AND PAINLESS IMMEDIATE RESULTS</h5>
<p>Results can be seen immediately after the treatment.</p>

<h5> WHY SKIN & YOU CLINIC FOR CELLULITE TREATMENT?</h5>
<p>At Skin & You Clinic, Mumbai, individualised treatment plans are formulated and combinations of treatments are carried out to achieve desired results.</p>

              </div>
            </div>

            <div className="col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InchLoss;
