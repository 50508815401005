// import React from "react";
// import Img1 from "../../assets/botox.jpg";
// import Img2 from "../../assets/laser-hair-reduction.jpg";
// import Img3 from "../../assets/lipocontrast.jpg";
// import Slider from "react-slick";
// import Img from "../../assets/slide-01.jpg";
// import { Link } from "react-router-dom";
// import BackgroundVideo from "../../assets/Website Video.mp4";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faMapMarkerAlt,
//   faEnvelope,
//   faPhoneAlt,
// } from "@fortawesome/free-solid-svg-icons";

// const Hero: React.FC = () => {
//   const settings = {
//     dots: false,
//     infinite: true,
//     arrows: false,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//   };

//   return (
//     <div className="section section-main-bunner context-dark" id="home">
//       <div
//         className="main-bunner-img bg-overlay-1"
//         // style={{
//         //   backgroundImage: `url(${Img})`,
//         //   backgroundSize: "cover",
//         // }}
//       />
//       <video
//         src={BackgroundVideo}
//         autoPlay
//         muted
//         loop
//         playsInline
//         style={{
//           position: "absolute",
//           top: 0,
//           left: 0,
//           width: "100%",
//           height: "100%",
//           objectFit: "cover",
//           zIndex: 1,
//         }}
//       />
//       <div className="main-bunner-inner">
//         <div className="container wide">
//           <div className="row justify-content-left">
//             <div className="col-lg-5">
//               <h1
//                 data-caption-animate="fadeInUp"
//                 data-caption-delay={100}
//                 className="animate__animated text-primary animate__bounceInLeft animate__slow"
//               >
//                 Look Better <br className="br-none" /> Feel Better
//               </h1>
//               <p
//                 className="lead animate__animated animate__bounceInRight animate__slow"
//                 data-caption-animate="fadeInUp"
//                 data-caption-delay={250}
//                 style={{ color: "black" }}
//               >
//                 Instantly Smooth Away Wrinkles and Scars for a Fresh, Youthful
//                 Glow
//               </p>
//               <div className="btn-wrap">
//                 <div className="group-xxl group-middle">
                
//                   <Link
//                     className="button button-white button-md button-round-2 animate__animated animate__bounceInUp animate__slow"
//                     to="/"
//                     data-caption-animate="fadeInUp"
//                     data-caption-delay={450}
//                     data-bs-toggle="modal"
//                     data-bs-target="#exampleModal2"
//                   >
//                     Call Now
//                   </Link>
//                   <Link
//                     className="button button-white button-md button-round-2 animate__animated animate__bounceInUp animate__slow"
//                     to="/"
//                     data-caption-animate="fadeInUp"
//                     data-caption-delay={450}
//                     data-bs-toggle="modal"
//                     data-bs-target="#exampleModal"
//                   >
//                     Book Now
//                   </Link>
//                 </div>
//               </div>
             
//             </div>
//           </div>
//         </div>
//       </div>

//       <div
//         className="modal fade"
//         id="exampleModal"
//         tabIndex={-1}
//         aria-labelledby="exampleModalLabel"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h1 className="modal-title fs-5" id="exampleModalLabel">
//                 Book Appointment
//               </h1>
//               <button
//                 type="button"
//                 className="btn-close"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//               />
//             </div>
//             <div className="modal-body">
//               <form>
//                 <div className="row g-3">
//                   <div className="col-12 col-sm-6">
//                     <input
//                       type="text"
//                       className="form-control bg-light border-0"
//                       placeholder="Your Name"
//                       style={{ height: 55 }}
//                     />
//                   </div>
//                   <div className="col-12 col-sm-6">
//                     <input
//                       type="email"
//                       className="form-control bg-light border-0"
//                       placeholder="Your Email"
//                       style={{ height: 55 }}
//                     />
//                   </div>

//                   <div className="col-12 col-sm-6">
//                     <input
//                       type="text"
//                       className="form-control bg-light border-0"
//                       placeholder="Your Query"
//                       style={{ height: 55 }}
//                     />
//                   </div>
//                   <div className="col-12 col-sm-6">
//                     <input
//                       type="number"
//                       className="form-control bg-light border-0"
//                       placeholder="Your Phone"
//                       style={{ height: 55 }}
//                     />
//                   </div>
//                   <div className="col-12">
//                     <textarea
//                       className="form-control bg-light border-0"
//                       rows={5}
//                       placeholder="Your Message"
//                     />
//                   </div>

//                   <div className="col-12">
//                     <button
//                       className="btn btn-primary w-100 py-3"
//                       type="submit"
//                     >
//                       Make An Appointment
//                     </button>
//                   </div>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div
//         className="modal fade"
//         id="exampleModal2"
//         tabIndex={-1}
//         aria-labelledby="exampleModalLabel2"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h1 className="modal-title fs-5" id="exampleModalLabel2">
//                 Call Now
//               </h1>
//               <button
//                 type="button"
//                 className="btn-close"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//               />
//             </div>
//             <div className="modal-body">
//               <h4 className="d-inline-block text-uppercase  mb-4 ">
//                 Get In Touch
//               </h4>
//               <p className="mb-4 text-dark">
//                 Let's Connect and Make Your Vision a Reality. Reach Out Today!
//               </p>
//               <p className="mb-2">
//                 <Link
//                   className="text-dark pe-3"
//                   to="https://maps.app.goo.gl/GvQUuDyFdPguhykk6"
//                   target="_blank"
//                 >
//                   <FontAwesomeIcon
//                     icon={faMapMarkerAlt}
//                     className="text-primary me-3"
//                   />
//                   {`115 B, Mittal Court, Nariman Point, Mumbai, Maharashtra 400021`}
//                 </Link>
//               </p>
//               <p className="mb-2">
//                 <Link
//                   className="text-dark pe-3"
//                   to="mailto:helpdesk@skinandyou.in"
//                   target="_blank"
//                 >
//                   <FontAwesomeIcon
//                     icon={faEnvelope}
//                     className="text-primary me-3"
//                   />
//                   helpdesk@skinandyou.in
//                 </Link>
//               </p>
//               <p className="mb-0">
//                 <Link
//                   className="text-dark pe-3"
//                   to="tel:+919920033331"
//                   target="_blank"
//                 >
//                   <FontAwesomeIcon
//                     icon={faPhoneAlt}
//                     className="text-primary me-3"
//                   />
//                   +91 99200 33331
//                 </Link>
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>

//   );
// };

// export default Hero;



// import React, { useState } from "react";
// import Img1 from "../../assets/botox.jpg";
// import Img2 from "../../assets/laser-hair-reduction.jpg";
// import Img3 from "../../assets/lipocontrast.jpg";
// import Slider from "react-slick";
// import Img from "../../assets/slide-01.jpg";
// import { Link } from "react-router-dom";
// import BackgroundVideo from "../../assets/Website Video.mp4";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faMapMarkerAlt,
//   faEnvelope,
//   faPhoneAlt,
// } from "@fortawesome/free-solid-svg-icons";

// const Hero: React.FC = () => {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     query: "",
//     phone: "",
//     message: "",
//   });
//   const [successMessage, setSuccessMessage] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");

//   const settings = {
//     dots: false,
//     infinite: true,
//     arrows: false,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//   };

//   const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     console.log("Form submitted:", formData); // Check if this logs when submitting

//     // Validate formData before sending
//     if (!formData.name || !formData.email || !formData.message) {
//         setErrorMessage("Please fill out all required fields.");
//         return;
//     }

//     try {
//         const response = await fetch("http://localhost:5000/send-email", {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(formData),
//         });

//         console.log("Response:", response); // Check the response object

//         if (response.ok) {
//             const responseData = await response.json(); // Parse the JSON response
//             console.log("Response Data---->:", responseData); // Log to console

//             setSuccessMessage("Email sent successfully!");
//             setErrorMessage("");
//             setFormData({ name: "", email: "", query: "", phone: "", message: "" }); // Reset form
//         } else {
//             // Handle error responses
//             const errorData = await response.json();
//             throw new Error(errorData.message || "Failed to send email");
//         }
//     } catch (error) {
//         console.error("Error:", error);
//         setErrorMessage("Error sending email. Please try again.");
//         setSuccessMessage("");
//     }
// };


//   return (
//     <div className="section section-main-bunner context-dark" id="home">
//       <div className="main-bunner-img bg-overlay-1" />
//       <video
//         src={BackgroundVideo}
//         autoPlay
//         muted
//         loop
//         playsInline
//         style={{
//           position: "absolute",
//           top: 0,
//           left: 0,
//           width: "100%",
//           height: "100%",
//           objectFit: "cover",
//           zIndex: 1,
//         }}
//       />
//       <div className="main-bunner-inner">
//         <div className="container wide">
//           <div className="row justify-content-left">
//             <div className="col-lg-5">
//               <h1 data-caption-animate="fadeInUp" data-caption-delay={100} className="animate__animated text-primary animate__bounceInLeft animate__slow">
//                 Look Better <br className="br-none" /> Feel Better
//               </h1>
//               <p className="lead animate__animated animate__bounceInRight animate__slow" data-caption-animate="fadeInUp" data-caption-delay={250} style={{ color: "black" }}>
//                 Instantly Smooth Away Wrinkles and Scars for a Fresh, Youthful Glow
//               </p>
//               <div className="btn-wrap">
//                 <div className="group-xxl group-middle">
//                   <Link className="button button-white button-md button-round-2 animate__animated animate__bounceInUp animate__slow" to="/" data-caption-animate="fadeInUp" data-caption-delay={450} data-bs-toggle="modal" data-bs-target="#exampleModal2">
//                     Call Now
//                   </Link>
//                   <Link className="button button-white button-md button-round-2 animate__animated animate__bounceInUp animate__slow" to="/" data-caption-animate="fadeInUp" data-caption-delay={450} data-bs-toggle="modal" data-bs-target="#exampleModal">
//                     Book Now
//                   </Link>
    
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
//         <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h1 className="modal-title fs-5" id="exampleModalLabel">Book Appointment</h1>
//               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
//             </div>
//             <div className="modal-body">
//               <form onSubmit={handleSubmit}>
//                 <div className="row g-3">
//                   <div className="col-12 col-sm-6">
//                     <input
//                       type="text"
//                       name="name"
//                       value={formData.name}
//                       onChange={handleChange}
//                       className="form-control bg-light border-0"
//                       placeholder="Your Name"
//                       style={{ height: 55 }}
//                       required
//                     />
//                   </div>
//                   <div className="col-12 col-sm-6">
//                     <input
//                       type="email"
//                       name="email"
//                       value={formData.email}
//                       onChange={handleChange}
//                       className="form-control bg-light border-0"
//                       placeholder="Your Email"
//                       style={{ height: 55 }}
//                       required
//                     />
//                   </div>
//                   <div className="col-12 col-sm-6">
//                     <input
//                       type="text"
//                       name="query"
//                       value={formData.query}
//                       onChange={handleChange}
//                       className="form-control bg-light border-0"
//                       placeholder="Your Query"
//                       style={{ height: 55 }}
//                       required
//                     />
//                   </div>
//                   <div className="col-12 col-sm-6">
//                     <input
//                       type="number"
//                       name="phone"
//                       value={formData.phone}
//                       onChange={handleChange}
//                       className="form-control bg-light border-0"
//                       placeholder="Your Phone"
//                       style={{ height: 55 }}
//                       required
//                     />
//                   </div>
//                   <div className="col-12">
//                     <textarea
//                       name="message"
//                       value={formData.message}
//                       onChange={handleChange}
//                       className="form-control bg-light border-0"
//                       rows={5}
//                       placeholder="Your Message"
//                       required
//                     />
//                   </div>
//                   <div className="col-12">
//                     <button className="btn btn-primary w-100 py-3" type="submit">
//                       Make An Appointment
//                     </button>
//                   </div>
//                 </div>
//               </form>
//               {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
//               {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="modal fade" id="exampleModal2" tabIndex={-1} aria-labelledby="exampleModalLabel2" aria-hidden="true">
//         <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h1 className="modal-title fs-5" id="exampleModalLabel2">Call Now</h1>
//               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
//             </div>
//             <div className="modal-body">
//               <h4 className="d-inline-block text-uppercase mb-4">Get In Touch</h4>
//               <p className="mb-4 text-dark">
//                 Let's Connect and Make Your Vision a Reality. Reach Out Today!
//               </p>
//               <p className="mb-2">
//                 <Link className="text-dark pe-3" to="https://maps.app.goo.gl/GvQUuDyFdPguhykk6" target="_blank">
//                   <FontAwesomeIcon icon={faMapMarkerAlt} className="text-primary me-3" />
//                   {`115 B, Mittal Court, Nariman Point, Mumbai, Maharashtra 400021`}
//                 </Link>
//               </p>
//               <p className="mb-2">
//                 <Link className="text-dark pe-3" to="mailto:helpdesk@skinandyou.in" target="_blank">
//                   <FontAwesomeIcon icon={faEnvelope} className="text-primary me-3" />
//                   helpdesk@skinandyou.in
//                 </Link>
//               </p>
//               <p className="mb-2">
//                 <Link className="text-dark pe-3" to="tel:+919167260217" target="_blank">
//                   <FontAwesomeIcon icon={faPhoneAlt} className="text-primary me-3" />
//                   +91 91672 60217
//                 </Link>
//               </p>
//               <p className="mb-2">
//                 <Link className="text-dark pe-3" to="tel:+918048788696" target="_blank">
//                   <FontAwesomeIcon icon={faPhoneAlt} className="text-primary me-3" />
//                   +91 80487 88696
//                 </Link>
//               </p>
//               <p className="mb-0">
//                 <Link className="text-dark pe-3" to="tel:+919822110907" target="_blank">
//                   <FontAwesomeIcon icon={faPhoneAlt} className="text-primary me-3" />
//                   +91 98221 10907
//                 </Link>
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Hero;


import React, { useState } from "react";
import Img1 from "../../assets/botox.jpg";
import Img2 from "../../assets/laser-hair-reduction.jpg";
import Img3 from "../../assets/lipocontrast.jpg";
import Slider from "react-slick";
import Img from "../../assets/slide-01.jpg";
import { Link } from "react-router-dom";
import BackgroundVideo from "../../assets/Website Video.mp4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import BookingModal from "./BookingModal"; // Import the BookingModal component

const Hero: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="section section-main-bunner context-dark" id="home">
      <div className="main-bunner-img bg-overlay-1" />
      <video
        src={BackgroundVideo}
        autoPlay
        muted
        loop
        playsInline
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: 1,
        }}
      />
      <div className="main-bunner-inner">
        <div className="container wide">
          <div className="row justify-content-left">
            <div className="col-lg-5">
              <h1 data-caption-animate="fadeInUp" data-caption-delay={100} className="animate__animated text-primary animate__bounceInLeft animate__slow">
                Look Better <br className="br-none" /> Feel Better
              </h1>
              <p className="lead animate__animated animate__bounceInRight animate__slow" data-caption-animate="fadeInUp" data-caption-delay={250} style={{ color: "black" }}>
                Instantly Smooth Away Wrinkles and Scars for a Fresh, Youthful Glow
              </p>
              <div className="btn-wrap">
                <div className="group-xxl group-middle">
                  <Link className="button button-white button-md button-round-2 animate__animated animate__bounceInUp animate__slow" to="/" onClick={handleOpenModal}>
                    Book Now
                  </Link>
                  <Link className="button button-white button-md button-round-2 animate__animated animate__bounceInUp animate__slow" to="/">
                    Call Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BookingModal showModal={showModal} onClose={handleCloseModal} /> {/* Render the BookingModal */}
      
      <div className="modal fade" id="exampleModal2" tabIndex={-1} aria-labelledby="exampleModalLabel2" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel2">Call Now</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <h4 className="d-inline-block text-uppercase mb-4 ">Get In Touch</h4>
              <p className="mb-4 text-dark">Let's Connect and Make Your Vision a Reality. Reach Out Today!</p>
              <p className="mb-2">
                <Link className="text-dark pe-3" to="https://maps.app.goo.gl/GvQUuDyFdPguhykk6" target="_blank">
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="text-primary me-3" />
                  Address
                </Link>
              </p>
              <p className="mb-2">
                <Link className="text-dark pe-3" to="mailto:info@beautyland.com">
                  <FontAwesomeIcon icon={faEnvelope} className="text-primary me-3" />
                  Email
                </Link>
              </p>
              <p className="mb-2">
                <Link className="text-dark pe-3" to="tel:+123456789">
                  <FontAwesomeIcon icon={faPhoneAlt} className="text-primary me-3" />
                  Phone
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;



// work..............
// import React, { useState } from 'react';

// const AppointmentForm = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     query: '',
//     message: '',
//   });
//   const [successMessage, setSuccessMessage] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');

//   // Update form data based on user input
//   const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   // Handle form submission
//   const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
//     console.log("Form submitted:", formData); // Check if this logs when submitting

//     // Validate formData before sending
//     if (!formData.name || !formData.email || !formData.message) {
//       setErrorMessage("Please fill out all required fields.");
//       return;
//     }

//     try {
//       const response = await fetch("http://localhost:5000/send-email", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(formData),
//       });

//       console.log("Response:", response); // Check the response object

//       if (response.ok) {
//         const responseData = await response.json(); // Parse the JSON response
//         console.log("Response Data---->:", responseData); // Log to console

//         setSuccessMessage("Email sent successfully!");
//         setErrorMessage("");
//         setFormData({ name: "", email: "", phone: "", query: "", message: "" }); // Reset form
//       } else {
//         // Handle error responses
//         const errorData = await response.json();
//         throw new Error(errorData.message || "Failed to send email");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       setErrorMessage("Error sending email. Please try again.");
//       setSuccessMessage("");
//     }
//   };

//   return (
//     <div className="container">
//       <h2>Make an Appointment</h2>

//       {successMessage && <div className="alert alert-success">{successMessage}</div>}
//       {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

//       <form>
//         <div className="form-group">
//           <label htmlFor="name">Name:</label>
//           <input
//             type="text"
//             id="name"
//             name="name"
//             value={formData.name}
//             onChange={handleChange}
//             className="form-control"
//             required
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="email">Email:</label>
//           <input
//             type="email"
//             id="email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//             className="form-control"
//             required
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="phone">Phone:</label>
//           <input
//             type="text"
//             id="phone"
//             name="phone"
//             value={formData.phone}
//             onChange={handleChange}
//             className="form-control"
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="query">Query:</label>
//           <input
//             type="text"
//             id="query"
//             name="query"
//             value={formData.query}
//             onChange={handleChange}
//             className="form-control"
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="message">Message:</label>
//           <textarea
//             id="message"
//             name="message"
//             value={formData.message}
//             onChange={handleChange}
//             className="form-control"
//             required
//           />
//         </div>

//         <div className="col-12">
//           <button
//             className="btn btn-primary w-100 py-3"
//             type="button" // Type is button to prevent form submission
//             onClick={handleSubmit} // Calls the modified handleSubmit function
//           >
//             Make An Appointment
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default AppointmentForm;
