// import React, { useState } from "react";

// interface BookingModalProps {
//   showModal: boolean;
//   onClose: () => void;
// }

// const BookingModal: React.FC<BookingModalProps> = ({ showModal, onClose }) => {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     query: "",
//     phone: "",
//     message: "",
//   });
//   const [successMessage, setSuccessMessage] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");

//   const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
//     e.preventDefault();

//     // Validate formData before sending
//     if (!formData.name || !formData.email || !formData.message) {
//       setErrorMessage("Please fill out all required fields.");
//       return;
//     }

//     try {
//       const response = await fetch("https://skin-you-backend.vercel.app/send-email", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(formData),
//       });

//       if (response.ok) {
//         const responseData = await response.json();
//         setSuccessMessage("Email sent successfully!");
//         setErrorMessage("");
//         setFormData({ name: "", email: "", query: "", phone: "", message: "" }); // Reset form
//       } else {
//         const errorData = await response.json();
//         throw new Error(errorData.message || "Failed to send email");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       setErrorMessage("Error sending email. Please try again.");
//       setSuccessMessage("");
//     }
//   };

//   return (
//     <>
//       {showModal && (
//         <div className="modal fade show" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="false" style={{ display: "block" }}>
//           <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
//             <div className="modal-content">
//               <div className="modal-header">
//                 <h1 className="modal-title fs-5" id="exampleModalLabel">Book Appointment</h1>
//                 <button type="button" className="btn-close" onClick={onClose} aria-label="Close" />
//               </div>
//               <div className="modal-body">
//                 <form onSubmit={handleSubmit}>
//                   <div className="row g-3">
//                     <div className="col-12 col-sm-6">
//                       <input type="text" className="form-control bg-light border-0" name="name" placeholder="Your Name" value={formData.name} onChange={handleChange} required style={{ height: 55 }} />
//                     </div>
//                     <div className="col-12 col-sm-6">
//                       <input type="email" className="form-control bg-light border-0" name="email" placeholder="Your Email" value={formData.email} onChange={handleChange} required style={{ height: 55 }} />
//                     </div>
//                     <div className="col-12 col-sm-6">
//                       <input type="text" className="form-control bg-light border-0" name="query" placeholder="Your Query" value={formData.query} onChange={handleChange} style={{ height: 55 }} />
//                     </div>
//                     <div className="col-12 col-sm-6">
//                       <input type="number" className="form-control bg-light border-0" name="phone" placeholder="Your Phone" value={formData.phone} onChange={handleChange} style={{ height: 55 }} />
//                     </div>
//                     <div className="col-12">
//                       <textarea className="form-control bg-light border-0" name="message" rows={5} placeholder="Your Message" value={formData.message} onChange={handleChange} required />
//                     </div>
//                     <div className="col-12">
//                       <button className="btn btn-primary w-100 py-3" type="submit">Make An Appointment</button>
//                     </div>
//                   </div>
//                   {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
//                   {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default BookingModal;

import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

interface BookingModalProps {
  showModal: boolean;
  onClose: () => void;
}

const BookingModal: React.FC<BookingModalProps> = ({ showModal, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    query: "",
    phone: "",
    message: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate formData before sending
    if (!formData.name || !formData.email || !formData.message) {
      toast.error("Please fill out all required fields.");
      return;
    }

    try {
       const response = await fetch("https://skin-you-backend.vercel.app/send-email", {
        // const response = await fetch("http://localhost:5000/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Appointment booked successfully!");
        setFormData({ name: "", email: "", query: "", phone: "", message: "" }); // Reset form
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to send email");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error sending email. Please try again.");
    }
  };

  return (
    <>
      {showModal && (
        <div className="modal fade show" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="false" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">Book Appointment</h1>
                <button type="button" className="btn-close" onClick={onClose} aria-label="Close" />
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-12 col-sm-6">
                      <input type="text" className="form-control bg-light border-0" name="name" placeholder="Your Name" value={formData.name} onChange={handleChange} required style={{ height: 55 }} />
                    </div>
                    <div className="col-12 col-sm-6">
                      <input type="email" className="form-control bg-light border-0" name="email" placeholder="Your Email" value={formData.email} onChange={handleChange} required style={{ height: 55 }} />
                    </div>
                    <div className="col-12 col-sm-6">
                      <input type="text" className="form-control bg-light border-0" name="query" placeholder="Your Query" value={formData.query} onChange={handleChange} style={{ height: 55 }} />
                    </div>
                    <div className="col-12 col-sm-6">
                      <input type="number" className="form-control bg-light border-0" name="phone" placeholder="Your Phone" value={formData.phone} onChange={handleChange} style={{ height: 55 }} />
                    </div>
                    <div className="col-12">
                      <textarea className="form-control bg-light border-0" name="message" rows={5} placeholder="Your Message" value={formData.message} onChange={handleChange} required />
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary w-100 py-3" type="submit">Make An Appointment</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Toast container to display toasts */}
      {/* <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
     */}
       <ToastContainer 
        position="top-right" 
        autoClose={5000} 
        hideProgressBar={false} 
        newestOnTop={false} 
        closeOnClick 
        rtl={false} 
        pauseOnFocusLoss 
        draggable 
        pauseOnHover 
        style={{ marginTop: "150px" }}  // Custom margin-top
      />
    
    </>
  );
};

export default BookingModal;
