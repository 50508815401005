import React, { useLayoutEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";

const FungalInfection: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="section section-lg bg-primary container-top">
        <div className="container ">
          <div className="text-center ">
            <h1>Fungal Infection</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-9">
              <div className="mb-5">
                <h5>PITYRIASIS VERSICOLOR</h5>
                <h5>ABOUT SKIN FUNGAL INFECTIONS</h5>
                <p>
                  Fungal infections are the most common of all skin infections.
                  They are particularly common in hot and humid climates.
                </p>

                <h5>CAUSES</h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    There is a higher risk for fungal infection of the body if
                    you
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Live in damp, humid or crowded conditions
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Share clothing, bedding or towels with someone who has a
                    fungal infection
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Sweat excessively
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Participate in contact sports, such as wrestling, football
                    or rugby
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Wear tight or restricted clothing
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Have a weakened immune system
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Obese
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Steroid overuse
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Some of the commonly encountered fungal infections
                  </p>
                </p>

                <h5>PITYRIASIS VERSICOLOR –</h5>
                <p>
                  Pityriasis versicolor occurs more frequently in hot and humid
                  tropical climates like Mumbai.
                </p>

                <h5>Areas:</h5>

                <p>
                  It commonly occurs on the upper part of the body and shoulder
                  areas, and also affects the face, scalp, and below the
                  breasts.
                </p>

                <p>fungal-infection-1</p>

                <h5>SIGNS AND SYMPTOMS:</h5>
                <p>
                  The rash presents as multiple white, pink to brown oval to
                  round patches with mild, fine scales. The characteristic of
                  the fungus is that it leaves areas of white patches (hypo
                  pigmentation) that can take up to 12 weeks to return to their
                  normal colour after disease clearance.
                </p>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    RINGWORM
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    SYMPTOMS
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    THE SYMPTOMS INCLUDE
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Itching
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Ring-shaped (therefore called as a ringworm) areas
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Red, raised and scaly patch with a red border and a clear or
                    normal-appearing centre
                  </p>
                </p>

                <h5>
                  DEPENDING ON THE AREAS INVOLVED THE VARIOUS AREAS WHERE
                  INFECTION CAN OCCUR ARE:
                </h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    On the scalp and Hair
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    On the face and beard area
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    On the foot and hand
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    On the body
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    On the groin and pubic area
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    On the nails
                  </p>
                </p>

                <h5>CANDIDIASIS –</h5>
                <p>
                  Candida is capable of producing infection of skin and mucous
                  membrane (such as mouth, penis, vagina) infections.
                </p>

                <h5>Symptoms and signs:</h5>

                <p>
                  Candidiasis is generally a red rash, with a variable degree of
                  itching and soreness. The area may appear moist red in colour
                  with a periphery of tiny raised lesions and pus filled tiny
                  boils.
                </p>

                <p>
                  In the mouth, the Candidiasis appears as whitish area/ plaque
                  that leave a raw bleeding surface if removed.
                </p>

                <h5>DIAGNOSIS OF FUNGAL INFECTION</h5>
                <p>
                  Ringworm is usually diagnosed by a dermatologist based on
                  assessing the individual’s medical history and a physical
                  examination. The clinical presentation is unique. Nail
                  clippings are required to examine ringworm in the nails.
                </p>
                <h5>TREATMENTS AT SKIN & YOU CLINIC FOR FUNGAL INFECTIONS</h5>
                <p>
                  Depending on the type and the site of the infection the
                  treatment is modified. Anti-fungal medications are the main
                  line of treatment are usually given for longer duration for
                  complete recovery
                </p>

                <p>
                  Prevention – This is possible by applying powder containing
                  clotrimazole, miconazole to areas prone to fungal infection
                  after bathing.
                </p>
                <p>
                  Topical Anti-Fungal – Various anti-fungals such as
                  clotrimazole, miconazole, Ketoconazole, terbinafine,
                  ciclopiroxolamine can be applied to the lesions generally
                  twice daily.
                </p>
                <p>
                  Systemic Anti-fungal – Such as Fluconazole, Terbinafine,
                  Itraconazole are given orally in combination with the topical
                  anti-fungal medications.
                </p>
                <p>
                  Duration of the Treatment – This depends on the site and
                  severity of infections. Because fungi can live indefinitely on
                  the skin, a recurrence of infection is possible if treatment
                  schedule is not followed as advised.
                </p>
                <h5>WHY SKIN & YOU CLINIC FOR TREATING FUNGAL INFECTION?</h5>
                <p>
                  We, at Skin & You Clinic, assess, examine and treat all
                  dermatological conditions with utmost care.
                </p>
              </div>
            </div>

            <div className="col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FungalInfection;
