import React, { useLayoutEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";

const RosaceaPinkFace: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="section section-lg bg-primary container-top">
        <div className="container ">
          <div className="text-center ">
            <h1>Rosacea / Pink Face</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-9">
              <div className="mb-5">
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Radiofrequency Skin Tightening
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Deep Chemical Peels
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Dermaroller
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Dermal Fillers Treatment
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Mesotherapy Treatment
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Alma Photo Facial
                  </p>
                </p>

                <h5>SKIN & FACIAL REDNESS (ROSACEA)</h5>
                <h5>ABOUT ROSACEA</h5>
                <p>
                  Rosacea is a common condition characterised by facial flushing
                  and other signs of redness, coarseness of skin and pimples
                  like eruption. It affects approximately 1 in 20 people.
                </p>

                <h5>OCCURRENCE</h5>
                <p>
                  Facialflushing could be seen from childhood or the early
                  teens. More common in fair-skinned individuals.
                </p>
                <p>
                  Commonly affects face and eyes, especially around the nose,
                  cheeks and chin and in between the brow.
                </p>
                <h5>SIGNS AND SYMPTOMS</h5>
                <p>
                  Easy blushing and flushing of the facial skin is the most
                  common sign.
                </p>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Warm, swollen skin especially around nose and cheeks (
                    common in women)
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Accompanying dandruff and oily skin
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Small red bumps, pimples, pus filled lesions (pustules)
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Broken dilated superficial blood vessels/ capillaries (
                    telangiectasia )
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Enlarged prominent oil glands over nose, chin and cheeks
                  </p>
                </p>

                <p>
                  50% of affected individuals will have eye issues
                  also-including redness, burning, gritty feeling in the eye,
                  foreign body sensation and swollen inflamed eyelids
                </p>

                <h5>CAUSES</h5>
                <p>
                  The exact cause of Rosacea is still unknown. But a number of
                  factors can aggravate or trigger Rosacea by increasing blood
                  flow to the surface of your skin. Some of them include
                </p>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Spicy foods
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Alcohol (especially red wine)
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Extreme temperatures
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Sunlight
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Stress, anger or embarrassment
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Strenuous exercise
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Hot baths, saunas
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Corticosteroids
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Some blood pressure medications
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Fragrances
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Astringents
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Exfoliating agents
                  </p>
                </p>

                <h5>DIAGNOSIS</h5>

                <p>
                  There’s no specific test for Rosacea; it is usually diagnosed
                  with a complete medical history and physical examination by a
                  dermatologist.
                </p>
              </div>
            </div>

            <div className="col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RosaceaPinkFace;
