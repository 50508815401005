import React, { useLayoutEffect } from "react";
import BlogImg1 from "../../../assets/massage-2-390x344.jpg";
import UserImg from "../../../assets/user.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faComment } from "@fortawesome/free-regular-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";

const LowLevelLightLaserHairLoss: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className="section section-lg bg-primary container-top">
        <div className="container ">
          <div className="text-center ">
            <h1>Low Level Light Laser for Hair Loss</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-9">
              <div className="mb-5">
                <h5>
                  SCIENCE & TECHNOLOGY UNITE TO GIVE YOU… THICKER, FULLER AND
                  HEALTHIER HAIR!
                </h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Safe and Effective
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Strengthens Hair Follicles
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Generates New Hair Growth
                  </p>
                </p>

                <h5>FEATURES & BENEFITS</h5>
                <p>
                  Hair loss, for millions, is an unfortunate
                  inevitability…However formost; it is also unnecessary and
                  entirely preventable!
                </p>

                <p>
                  Safe and effective, the igrow is a revolutionary Hair growth
                  system that provides full scalp coverage, simple to use igrow
                  incorporates Apira Science’s dual light Lazer and LED design.
                </p>

                <h5>GROWS HAIR</h5>
                <p>
                  igrow’s proprietary low-Level Light Therapy (LLLT) technology
                  utilizes ahighly effective combinationof red lazer and LED
                  light diodes to effectively stimulate and energize cellular
                  activity causing an uptake in the natural function of the hair
                  follicle. This proven 4 to 6 month process reverses hair
                  thinning and loss.
                </p>

                <h5>SAFE AND EFFECTIVE</h5>
                <p>
                  The igrow is a safe and effective device, designed and
                  produced ,Specifically for the purpose of growing hair in
                  people afflicted withAndrogenetic Alopecia (thinning hair).
                  igrows non-invasive, proprietaryLow- Level Light Therapy
                  (LLLT) technology has no side-effects, unlike Pharmaceuticals,
                  topical and pills used to treat hair-loss.
                </p>

                <h5>AFFORDABLE</h5>
                <p>
                  igrow is not only highly effective, it’s affordable as well.
                </p>

                <h5>SCIENCE AND TECHNOLOGY</h5>
                <p>
                  The revolutionary igrow features an optimum blend of proven
                  science and technological achievement,borne from decades of
                  research and development. A worldwide team of scientists,
                  doctors and medical engineershave incorporated the identical
                  low-level laser technology used by professional hair loss
                  medical experts and by hair restoration physicians,into the
                  igrow to effectively grow hair.
                </p>

                <h5>OPTIMUM SCALP COVERAGE</h5>
                <p>
                  The igrow is designed and crafted to effectively cover the
                  scalp, and engineered tofocus on areas prone to hair loss.
                </p>

                <h5>PROGRAMMED FOR EVERYONE</h5>
                <p>
                  The igrow is engineered to address, treat and ultimately
                  reverse the effects of Androgenetic-Alopecia(thinning hair
                  leading to hair loss).
                </p>

                <h5>COMPARE</h5>
                <p>
                  When comparedto other low-level laser therapy devices, the
                  igrow trulyis unparalleled in its inherent features and
                  benifits.
                </p>

                <h5>RESULTS</h5>
                <p>
                  It’s been clinically proven that most men and women prone to
                  genetic hairloss or thinning hair will benefit from low-level
                  lasers. It’s an innovative, amazing technology that can grow
                  hair and prevent thepremature loss of hair.
                </p>

                <p>
                  With regular use of igrow, individuals Can expect to see a
                  gradual improvement ,In hair condition within a 12-16-week
                  period.Individual results will vary. For best Results the
                  igrow should be used two times per week on non-consecutive
                  days. Consistent use provides optimum results.
                </p>

                <p>
                  Low-Level Light Therapy (LLLT) has been shown to stop hair
                  loss and help grow hair for menand women with genetic forms of
                  hair loss,such as pattern baldness.
                </p>

                <h5>ABOUT LOW-LEVEL LIGHT THERAPY (LLLT)</h5>
                <p>
                  Lasers are widely used in medicine and also through outa
                  variety of industries in a multitude of varying capacities.
                  The laser energy used to growhair is visible in the red light
                  spectrum. The laser light used in treating hair loss and other
                  medical conditionsis defined as a Low-Level Laser Therapy
                  (LLLT).LLLT is sometimes included under a broader definition
                  of photo-therapy called low-light therapy where the
                  light-emitting device may be a laser and/ora light-emitting
                  diode(LED).
                </p>

                <h5>BEFORE TREATMENT</h5>
                <p>
                  Thinning hair and hairloss is primarily aGenetically driven
                  conditionCalled Androgenetic Alopecia.over time,this condition
                  deconstructsor damages follicle cells and causes hair to
                  progressively thin untilthe hair follicleultimately dies,
                  unable to produce hair again.
                </p>

                <h5>RECIEVING TREATMENT</h5>
                <p>
                  Non-Invasive Low-Level Laser Therapy at a
                  scientificallyestablished and clinically provenwavelength
                  (approximately 655 nano meters) can re-energize unhealthy
                  follicle cells, andcorrespondingly stimulate and stabilizethe
                  production of fuller, thicker and healthier hair.
                </p>

                <h5>AFTER TREATMENT</h5>
                <p>
                  When used consistently,Low-Level Laser Therapyhas been shown
                  to effectivelytreat the primary effects of Androgenetic
                  Alopecia(thinning hair). LLLT can effectively suspend hair
                  loss ,and ultimatelyreturn thin hair to a fuller, thicker
                  andhealthier state.
                </p>

                <h5>WHY SKIN AND YOU CLINIC FOR LASER LIGHT THERAPY ?</h5>
                <p>
                  We at skin and you clinic have been using the low level light
                  with good results . A hair regrowth is noticed at the end of
                  the treatment we advise you then how to maintain the hair with
                  lasting results . we use the best technology which is safe
                  ,effective with no downtime.
                </p>
              </div>
            </div>

            <div className="col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LowLevelLightLaserHairLoss;
