import React, { useLayoutEffect } from "react";
import ArticleVideo from "../../../assets/Treatments/Viscoderm.mp4";
import UserImg from "../../../assets/user.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faComment } from "@fortawesome/free-regular-svg-icons";
import SideBar from "./SideBar";

const Viscoderm: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className="section section-lg bg-primary container-top">
        <div className="container ">
          <div className="text-center ">
            <h1>Viscoderm</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-9">
              <div className="mb-5">
                <video
                  src={ArticleVideo}
                  autoPlay
                  muted
                  loop
                  playsInline
                  className="img-fluid w-100 rounded mb-5"
                  style={{ height: "600px" }}
                />
                <h3 className="mb-4">
                  Viscoderm: Advanced Skin Hydration and Revitalization
                </h3>

                <p>
                  At Skin & You Clinic, Viscoderm treatments are designed to
                  enhance skin hydration and rejuvenation, giving you a more
                  youthful, vibrant complexion. Viscoderm is a cutting-edge
                  hyaluronic acid-based treatment that deeply nourishes and
                  revitalizes the skin.
                </p>

                <h5>What is Viscoderm?</h5>

                <p>
                  Viscoderm is a non-invasive skin treatment that involves the
                  injection of a hyaluronic acid gel into the skin. Hyaluronic
                  acid is a naturally occurring substance in the skin that helps
                  retain moisture and maintain elasticity. Viscoderm works to
                  restore the skin’s hydration levels, smooth out fine lines,
                  and improve overall skin quality.
                </p>

                <h5>How It Works:</h5>

                <p>
                  The Viscoderm treatment involves the precise injection of
                  hyaluronic acid into the mid to deep dermal layers of the
                  skin. This gel replenishes moisture and stimulates the skin’s
                  natural hydration processes. The treatment is designed to
                  enhance skin texture, elasticity, and firmness by delivering
                  essential nutrients directly where they are needed most.
                </p>

                <h5>Benefits:</h5>

                <p>
                  Deep Hydration: Restores and maintains optimal moisture levels
                  in the skin. Smooths Fine Lines: Reduces the appearance of
                  fine lines and wrinkles. Improves Skin Texture: Enhances skin
                  elasticity and firmness. Revitalizes Dull Skin: Adds radiance
                  and a healthy glow to tired, dull skin.
                </p>

                <h5>The Procedure:</h5>

                <p>
                  Viscoderm treatment is minimally invasive and typically takes
                  about 30 minutes. The procedure involves the use of a fine
                  needle to inject the hyaluronic acid gel into targeted areas.
                  A local anesthetic may be applied to ensure comfort during the
                  treatment. There is usually minimal downtime, and you can
                  resume normal activities immediately.
                </p>

                <h5>Results:</h5>

                <p>
                  You will notice an improvement in skin hydration and texture
                  within a few days, with optimal results visible after a few
                  weeks. The effects of Viscoderm can last several months,
                  depending on your skin type and individual response to the
                  treatment.
                </p>

                <h5>Why Choose Skin & You Clinic for Viscoderm?</h5>

                <p>
                  At Skin & You Clinic, our skilled professionals are dedicated
                  to providing top-quality Viscoderm treatments tailored to your
                  specific needs. We use the latest techniques and
                  highest-quality products to ensure safe, effective results
                  that enhance your natural beauty and rejuvenate your skin.
                </p>
              </div>
            </div>

            <div className="col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Viscoderm;
